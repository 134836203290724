<template>
  <div>
    <div class="flex space-between pd-card">
      <a-menu v-model="selectedMenu" mode="horizontal" @select="selectMenu">
        <a-menu-item v-for="item of menus" :key="item.id" :disabled="item.disabled">
          {{ item.title }}
        </a-menu-item>
      </a-menu>
    </div>

    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'Detail',
  watch: {
    '$route.path'() {
      this.executeSelection(this.$route.path);
    },
  },
  data() {
    return {
      menus: [
        {
          id: 1,
          title: '基础信息',
          path: '/index/substance/found/vote/detail/basic',
          disabled: false,
        },
        {
          id: 2,
          title: '内容上传',
          path: '/index/substance/found/vote/detail/content',
          disabled: false,
        },
      ],
      selectedMenu: [1],
    };
  },
  created() {
    this.executeSelection(this.$route.path);
  },
  methods: {
    executeSelection(path) {
      this.menus.forEach(menu => {
        if (menu.path === path) {
          this.selectedMenu = [menu.id];
        }
      });
    },
    selectMenu({ key }) {
      const path = this.getPath(key);
      this.$router.replace({
        path,
        query: {
          ...(this.$route.query || {}),
        },
      });
    },
    getPath(key) {
      const [{ path }] = this.menus.filter(menu => menu.id === key && menu.path);
      return path || '';
    },
  },
};
</script>

<style scoped lang="scss">

</style>
