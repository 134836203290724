<template>
  <div>
    <div class="flex flex-end mb-20 pt-10">
      <a-button @click="onCreate">添加新页面</a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="id"
             class="table small-cell-table">

      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="onEdit(record)">编辑</a-button>
        <a-button v-if="record.status" type="link" @click="onChangeStatus(record, 0)">下架</a-button>
        <a-button v-else type="link" @click="onChangeStatus(record, 1)">上架</a-button>
      </template>

      <template slot="footer">
        <Pagination v-if="pagination && pagination.total"
                    :pagination="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>
  </div>
</template>

<script>
import {
  getVoteList,
  updateVoteStatus,
} from './api';

import Common from '@/utils/Common';

import Pagination, { getPagination } from '@components/Pagination';

const columns = [
  {
    width: '100px',
    align: 'center',
    title: '名称',
    dataIndex: 'title',
    key: 'title',
  },
  {
    width: '180px',
    align: 'center',
    title: '地址',
    dataIndex: 'vote_url',
    key: 'vote_url',
  },
  {
    width: '100px',
    align: 'center',
    title: '开始时间',
    dataIndex: 'start_day',
    key: 'start_day',
  },
  {
    width: '100px',
    align: 'center',
    title: '结束时间',
    dataIndex: 'end_day',
    key: 'end_day',
  },
  {
    width: '80px',
    align: 'center',
    title: '状态',
    dataIndex: 'status_name',
    key: 'status_name',
  },
  {
    width: '100px',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

export default {
  name: 'List',
  components: {
    Pagination,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onRefresh() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = {
        page,
        per_page: pageSize,
      };

      const data = await getVoteList(params).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        return;
      }
      this.data = data?.data || [];

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    onCreate() {
      this.$router.push({
        path: '/index/substance/found/vote/detail/basic',
      });
    },
    onEdit(record) {
      this.$router.push({
        path: '/index/substance/found/vote/detail/basic',
        query: {
          id: record.id,
        },
      });
    },
    onChangeStatus(record, status) {
      this.$confirm({
        content: `确认${status ? '上架' : '下架'}？`,
        onOk: () => this.confirmChangeStatus(record, status),
      });
    },
    async confirmChangeStatus(record, status) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = await updateVoteStatus({
        id: record.id,
        status,
      }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data.message || '保存成功');
      this.onRefresh();
    },
  },
};
</script>

<style scoped lang="scss">

</style>
